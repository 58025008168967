<template>
	<div>

                <el-form label-position='top' label-width="150px">
                <el-form-item 
                    v-for="(item) in playText"
                    :key='item.id'
                    :label="item.questiontext" >
                    <div>
                        <el-input v-if='item.texttype=="text"'  v-model="playForm[item.keyname]"></el-input>
                        <el-select style="width:100%" v-if='item.texttype=="select"' v-model="playForm[item.keyname]" placeholder="">
                          <el-option v-for="(itemS) in item.opition" :key="itemS.id" :label="itemS.answertext" :value="itemS.answertext"></el-option>
                        </el-select>
                        <el-input  v-if='item.texttype=="textarea"' type='textarea'  v-model="playForm[item.keyname]"></el-input>
                        <div v-if='item.texttype=="radio"'>
                            <el-radio  v-for="(itemS) in item.opition" :key="itemS.id" v-model="playForm[item.keyname]"  :label="itemS.answertext">{{itemS.answertext}}</el-radio>
                        </div>
                        <el-checkbox-group v-if='item.texttype=="checkbox"' v-model="playForm[item.keyname]">
                           <el-checkbox v-for="(itemS) in item.opition" :key="itemS.id" :label="itemS.answertext" >{{itemS.answertext}}</el-checkbox>
                        </el-checkbox-group>
                    </div>
		        </el-form-item>
                </el-form>
	</div>
</template>
<script>
	export default {
		mounted(){
			// if(window.location.href.split('?').length==1){
			// 	this.$message.error('未知活动')
			// }else{
				// this.actuuid=window.location.href.split('?')[1].split('&')[0].split('=')[1]
				// this.actcode=window.location.href.split('?')[1].split('&')[1].split('=')[1]//获取url的参数
				// this.getList()
                this.playText=[
                    {id:'a',texttype:'text',isnull:true,questiontext:'你的姓名',},
                    {id:'b',texttype:'radio',isnull:true,questiontext:'你的性别',opition:[{id:1,answertext:'男'},{id:2,answertext:'女'}]},
                    {id:'c',texttype:'select',isnull:true,questiontext:'你的年龄段',opition:[{id:1,answertext:'10-20'},{id:2,answertext:'40-50'}]},
                    {id:'d',texttype:'checkbox',isnull:true,questiontext:'你的爱好',opition:[{id:1,answertext:'吃饭'},{id:2,answertext:'睡觉'}]},
                    {id:'e',texttype:'textarea',isnull:true,questiontext:'备注'},
                ]
                let arr = {}
                  this.playText.map(item=>{
                    item.keyname='value'+item.id
                    arr['value'+item.id]=null
                    if(item.texttype=='checkbox'){
                      arr['value'+item.id]=[]
                    }
                  })
                  this.playForm=arr
			// }				
		},
		data() {
			return {
				toppic:'',
				playForm:{},//问卷填报内容
                playText:[],//问卷类填报内容格式
			};
		},
		methods: {
			getList(){
              this.$axios({
					method: 'post',
					url: '/PersonJoin/ActivityQuery',
					params: {
						actuuid: this.actuuid,
						actcode: this.actcode,
					}
				}).then((res) => {
                    if(res.data.result==200){
						this.iptList=res.data.detail.activitykeylist
						this.toppic=res.data.detail.toppicpath
                    	res.data.detail.activitykeylist.map(item=>{
                    	    if(item.keytype=='select'||item.keytype=='radio'||item.keytype=='checkbox'){
                    	        this.allselect[item.keyname]=item.listtext
                    	    }
                    	})
                    	let arr={}
            			this.iptList.map(item=>{
            			    if(item.keytype=='checkbox'){
            			        arr[item.keyname]=[]
            			    }else{
            			        arr[item.keyname]=''
            			    } 
            			})
            			this.ruleForm=arr
						this.isAct=true
                    }else{
                        this.$message.error(res.data.description);
                    }
                })        
      		},//获取列表
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						var keyvalue=''
						this.iptList.map(item=>{
							for(let key in this.ruleForm){
								if(item.keyname==key){
									keyvalue+=item.textdesc+'é'+this.ruleForm[key]+'||'
									return
								}
							}
						})
						keyvalue=keyvalue.slice(0,keyvalue.length-2)
						this.$axios({
							method: 'post',
							url: '/PersonJoin/joinAdd',
							headers: {
								'Content-Type': 'multipart/form-data'
							},
							params: {
								actuuid: this.actuuid,
								actcode: this.actcode,
								keyvalue: keyvalue
                               },
						}).then((res) => {
							if (res.data.result == 200) {
								this.$message.success('感谢您的参与');
								this.$refs.ruleForm.resetFields();
								setTimeout(()=>{
									window.location.reload()
								},500)
							} else {
								this.$message.error(res.data.description);
							}
						})
				} else {
						this.$message.warning('您还有问题或选项没有填写哦');
					}
				});
				
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},//重置
		}
	}
</script>
<style>
    .el-form-item__error{
		color: red;
    }
	.el-form-item__label{
		color: black;
		padding-bottom: 0 !important;
	}
	.el-form-item {
		width: 100%;
		margin-bottom: 5px;
		padding: 0 20px;
		box-sizing: border-box;
	}
	.el-checkbox__input.is-checked + .el-checkbox__label {
		/* color: #FFD800 !important; */
   	}/*多选文字 */
   .el-checkbox__label{
		color:black
   	}/*多选选中文字 */
   	.el-radio__input.is-checked + .el-radio__label {
		/* color: #FFD800 !important; */
	}/*单选选中文字 */
	.el-radio__label{
		color:black
   	}/*单选文字 */
</style>
